$body-font: 'Inter, sans-serif';
$grid-gutter: 15px;

$color: (
    primary: #7e482b,
    secondary: #d9af97,
    text: #1e1e1e,
    placeholder: #8a8e8d,
    footer-text: #fffefe,
);


@mixin screen($size, $block: null) {
    $sizes: (
        mobile-only: "max-width: 767px",
        medium: "min-width: 768px",
        //tablet-only: "min-width: 768px) and (max-width: 1219px",
        large: "min-width: 1345px",
    );

    @if (map-has_key($sizes, $size)) {
        $size: map-get($sizes, $size);
    } @else if (type_of($size) == 'number') {
        $size: "min-width: #{$size}px";
    }

    @media screen and (#{unquote($size)}) {
        @if ($block) {
            #{$block} {
                @content;
            }
        } @else {
            @content;
        }
    }
}

@mixin link($animate: true, $colorName: primary) {
    position: relative;
    display: inline-block;
    padding-right: 115px;
    border: none;
    background: none;
    color: map-get($color, $colorName);
    text-transform: uppercase;
    font-weight: map-get($font-weight, semibold);
    letter-spacing: 1px;
    cursor: pointer;

    &::before {
        content: "";
        position: absolute;
        top: 10px;
        right: 0;
        width: 90px;
        height: 2px;
        margin-left: -1px;
        background: map-get($color, $colorName);
    }

    @if $animate {
        &:hover::before {
            animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
        }
    }

    &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 7px;
        display: block;
        margin: 0;
        padding: 0;
        width: 8px;
        height: 8px;
        border-top: 2px solid map-get($color, $colorName);
        border-right: 2px solid map-get($color, $colorName);
        transform: rotate(45deg);
    }

    @keyframes elasticus {
        0% {
            transform-origin: 0 0;
            transform: scale(0, 1);
        }
        50% {
            transform-origin: 0 0;
            transform: scale(1, 1);
        }
        50.1% {
            transform-origin: 100% 0;
            transform: scale(1, 1);
        }
        100% {
            transform-origin: 100% 0;
            transform: scale(0, 1);
        }
    }

}
