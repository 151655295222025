@font-face {
    font-family: 'Playfair Display';
    src: local('Playfair Display Black Italic'), local('PlayfairDisplay-BlackItalic'),
        url('../fonts/PlayfairDisplay-BlackItalic.woff2') format('woff2'),
        url('../fonts/PlayfairDisplay-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: local('Playfair Display Black'), local('PlayfairDisplay-Black'),
        url('../fonts/PlayfairDisplay-Black.woff2') format('woff2'),
        url('../fonts/PlayfairDisplay-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: local('Playfair Display Bold'), local('PlayfairDisplay-Bold'),
        url('../fonts/PlayfairDisplay-Bold.woff2') format('woff2'),
        url('../fonts/PlayfairDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: local('Playfair Display ExtraBold Italic'), local('PlayfairDisplay-ExtraBoldItalic'),
        url('../fonts/PlayfairDisplay-ExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/PlayfairDisplay-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: local('Playfair Display Medium Italic'), local('PlayfairDisplay-MediumItalic'),
        url('../fonts/PlayfairDisplay-MediumItalic.woff2') format('woff2'),
        url('../fonts/PlayfairDisplay-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: local('Playfair Display Bold Italic'), local('PlayfairDisplay-BoldItalic'),
        url('../fonts/PlayfairDisplay-BoldItalic.woff2') format('woff2'),
        url('../fonts/PlayfairDisplay-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: local('Playfair Display Medium'), local('PlayfairDisplay-Medium'),
        url('../fonts/PlayfairDisplay-Medium.woff2') format('woff2'),
        url('../fonts/PlayfairDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: local('Playfair Display SemiBold Italic'), local('PlayfairDisplay-SemiBoldItalic'),
        url('../fonts/PlayfairDisplay-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/PlayfairDisplay-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'),
        url('../fonts/PlayfairDisplay-Regular.woff2') format('woff2'),
        url('../fonts/PlayfairDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: local('Playfair Display ExtraBold'), local('PlayfairDisplay-ExtraBold'),
        url('../fonts/PlayfairDisplay-ExtraBold.woff2') format('woff2'),
        url('../fonts/PlayfairDisplay-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: local('Playfair Display Italic'), local('PlayfairDisplay-Italic'),
        url('../fonts/PlayfairDisplay-Italic.woff2') format('woff2'),
        url('../fonts/PlayfairDisplay-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Playfair Display';
    src: local('Playfair Display SemiBold'), local('PlayfairDisplay-SemiBold'),
        url('../fonts/PlayfairDisplay-SemiBold.woff2') format('woff2'),
        url('../fonts/PlayfairDisplay-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter ExtraLight'), local('Inter-ExtraLight'),
        url('../fonts/Inter-ExtraLight.woff2') format('woff2'),
        url('../fonts/Inter-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter Light'), local('Inter-Light'),
        url('../fonts/Inter-Light.woff2') format('woff2'),
        url('../fonts/Inter-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter ExtraBold'), local('Inter-ExtraBold'),
        url('../fonts/Inter-ExtraBold.woff2') format('woff2'),
        url('../fonts/Inter-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter Bold'), local('Inter-Bold'),
        url('../fonts/Inter-Bold.woff2') format('woff2'),
        url('../fonts/Inter-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter Black'), local('Inter-Black'),
        url('../fonts/Inter-Black.woff2') format('woff2'),
        url('../fonts/Inter-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter Thin'), local('Inter-Thin'),
        url('../fonts/Inter-Thin.woff2') format('woff2'),
        url('../fonts/Inter-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter SemiBold'), local('Inter-SemiBold'),
        url('../fonts/Inter-SemiBold.woff2') format('woff2'),
        url('../fonts/Inter-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter Medium'), local('Inter-Medium'),
        url('../fonts/Inter-Medium.woff2') format('woff2'),
        url('../fonts/Inter-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter Regular'), local('Inter-Regular'),
        url('../fonts/Inter-Regular.woff2') format('woff2'),
        url('../fonts/Inter-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
