.section-footer {
    color: map-get($color, footer-text);
    background: map-get($color, primary);
    font-size: 14px;

    &__inside {
        padding-top: 44px;
    }

    &__logo {
        display: block;
        width: 245px;
        height: 50px;
        background: url("../../images/logo--white.svg") 0 0/contain no-repeat;
    }

    .content-text.address {
        margin-left: 65px;
    }

    h1 {
        margin: 60px 0;
        font-size: 43px;
        line-height: 43px;
        font-weight: map-get($font-weight, semibold);

        em {
            position: relative;
            z-index: 10;
            display: inline-block;
            font-family: 'Playfair Display', serif;

            &::after {
                content: "";
                position: absolute;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 9px;
                background: map-get($color, secondary);
                z-index: -1;
            }
        }
    }

    .content-text {
        h2 {
            margin: 0;
            padding-top: 50px;
            text-align: center;
            border-top: 1px dotted #fff;
            font-weight: map-get($font-weight, semibold);

            + .rte {
                text-align: center;
            }
        }
    }

    .content-hyperlink {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 50px;

        a {
            @include link(true, footer-text);
        }
    }

    .row-dir-md--row-reverse .col + .col .content-text {
        opacity: .3;
        padding: 30px 0;

        p {
            margin: 0;
        }

        a {
            text-decoration: underline;
        }
    }
}

@include screen(medium, '.section-footer') {
    &__inside {
        padding-bottom: 30px;
    }

    &__logo {
        width: 328px;
        height: 70px;
    }

    .content-text.address {
        margin-left: 85px;
    }

    h1 {
        margin: -40px 0 40px;
        font-size: 70px;
        line-height: 76px;
    }

    .content-text {
        h2 {
            &,
            + .rte {
                text-align: left;
            }
        }
    }

    .content-hyperlink {
        margin-bottom: 30px;
        justify-content: flex-start;

        .explanation {
            padding: 0;
        }

        input.text {
            margin-bottom: 0;
        }
    }
}
