.section-header__inside,
.section-main__inside,
.section-footer__inside {
    padding: 0 20px;

    @include screen(medium) {
        max-width: 1315px;
        margin: 0 auto;
        padding: 0;
    }
}
