@charset "UTF-8";
@font-face {
  font-family: "Playfair Display";
  src: local("Playfair Display Black Italic"), local("PlayfairDisplay-BlackItalic"), url("../fonts/PlayfairDisplay-BlackItalic.woff2") format("woff2"), url("../fonts/PlayfairDisplay-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Playfair Display";
  src: local("Playfair Display Black"), local("PlayfairDisplay-Black"), url("../fonts/PlayfairDisplay-Black.woff2") format("woff2"), url("../fonts/PlayfairDisplay-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Playfair Display";
  src: local("Playfair Display Bold"), local("PlayfairDisplay-Bold"), url("../fonts/PlayfairDisplay-Bold.woff2") format("woff2"), url("../fonts/PlayfairDisplay-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Playfair Display";
  src: local("Playfair Display ExtraBold Italic"), local("PlayfairDisplay-ExtraBoldItalic"), url("../fonts/PlayfairDisplay-ExtraBoldItalic.woff2") format("woff2"), url("../fonts/PlayfairDisplay-ExtraBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Playfair Display";
  src: local("Playfair Display Medium Italic"), local("PlayfairDisplay-MediumItalic"), url("../fonts/PlayfairDisplay-MediumItalic.woff2") format("woff2"), url("../fonts/PlayfairDisplay-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Playfair Display";
  src: local("Playfair Display Bold Italic"), local("PlayfairDisplay-BoldItalic"), url("../fonts/PlayfairDisplay-BoldItalic.woff2") format("woff2"), url("../fonts/PlayfairDisplay-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Playfair Display";
  src: local("Playfair Display Medium"), local("PlayfairDisplay-Medium"), url("../fonts/PlayfairDisplay-Medium.woff2") format("woff2"), url("../fonts/PlayfairDisplay-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Playfair Display";
  src: local("Playfair Display SemiBold Italic"), local("PlayfairDisplay-SemiBoldItalic"), url("../fonts/PlayfairDisplay-SemiBoldItalic.woff2") format("woff2"), url("../fonts/PlayfairDisplay-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Playfair Display";
  src: local("Playfair Display Regular"), local("PlayfairDisplay-Regular"), url("../fonts/PlayfairDisplay-Regular.woff2") format("woff2"), url("../fonts/PlayfairDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Playfair Display";
  src: local("Playfair Display ExtraBold"), local("PlayfairDisplay-ExtraBold"), url("../fonts/PlayfairDisplay-ExtraBold.woff2") format("woff2"), url("../fonts/PlayfairDisplay-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Playfair Display";
  src: local("Playfair Display Italic"), local("PlayfairDisplay-Italic"), url("../fonts/PlayfairDisplay-Italic.woff2") format("woff2"), url("../fonts/PlayfairDisplay-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Playfair Display";
  src: local("Playfair Display SemiBold"), local("PlayfairDisplay-SemiBold"), url("../fonts/PlayfairDisplay-SemiBold.woff2") format("woff2"), url("../fonts/PlayfairDisplay-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: local("Inter ExtraLight"), local("Inter-ExtraLight"), url("../fonts/Inter-ExtraLight.woff2") format("woff2"), url("../fonts/Inter-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: local("Inter Light"), local("Inter-Light"), url("../fonts/Inter-Light.woff2") format("woff2"), url("../fonts/Inter-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: local("Inter ExtraBold"), local("Inter-ExtraBold"), url("../fonts/Inter-ExtraBold.woff2") format("woff2"), url("../fonts/Inter-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: local("Inter Bold"), local("Inter-Bold"), url("../fonts/Inter-Bold.woff2") format("woff2"), url("../fonts/Inter-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: local("Inter Black"), local("Inter-Black"), url("../fonts/Inter-Black.woff2") format("woff2"), url("../fonts/Inter-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: local("Inter Thin"), local("Inter-Thin"), url("../fonts/Inter-Thin.woff2") format("woff2"), url("../fonts/Inter-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: local("Inter SemiBold"), local("Inter-SemiBold"), url("../fonts/Inter-SemiBold.woff2") format("woff2"), url("../fonts/Inter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: local("Inter Medium"), local("Inter-Medium"), url("../fonts/Inter-Medium.woff2") format("woff2"), url("../fonts/Inter-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: local("Inter Regular"), local("Inter-Regular"), url("../fonts/Inter-Regular.woff2") format("woff2"), url("../fonts/Inter-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;
}

input,
button,
textarea,
select {
  margin: 0;
  padding: 0;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
  font-size: 99%;
  border-radius: 0;
}

textarea,
button,
input[type=email],
input[type=tel],
input[type=text],
input[type=number],
input[type=submit],
input[type=search] {
  -webkit-appearance: none;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button {
  display: none;
}

textarea[disabled],
button[disabled],
input[type=email][disabled],
input[type=tel][disabled],
input[type=text][disabled],
input[type=number][disabled],
input[type=submit][disabled],
input[type=search][disabled] {
  cursor: not-allowed;
}

select[disabled],
button[disabled],
input[disabled],
input[type=checkbox][disabled] + label,
input[type=radio][disabled] + label {
  cursor: not-allowed;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

table {
  font-size: inherit;
  border-collapse: collapse;
  border-spacing: 0;
}

table td, table th {
  padding: 4px 15px;
  vertical-align: top;
}

tr > :first-child {
  padding-left: 0;
}

th {
  font-weight: 500;
}

caption,
strong,
b {
  font-weight: 700;
}

ul ul {
  margin-bottom: 0;
}

form,
figure,
blockquote {
  margin: 0;
  padding: 0;
}

img,
video,
iframe {
  max-width: 100%;
  height: auto;
  border: none;
  vertical-align: middle;
}

header,
footer,
nav,
section,
main,
aside,
article,
figure,
figcaption {
  display: block;
}

html {
  font-size: 16px;
}

body {
  line-height: 1.625;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #1e1e1e;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

a {
  color: inherit;
  text-decoration: none;
}

caption,
th,
td {
  text-align: left;
  text-align: start;
}

abbr,
acronym {
  font-variant: normal;
  border-bottom: 1px dotted #1e1e1e;
  cursor: help;
}

blockquote,
q {
  quotes: none;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

del {
  text-decoration: line-through;
}

ins {
  text-decoration: none;
}

pre,
code {
  font-family: monospace;
}

/* Vertical align */
.float_left {
  float: left;
}

.float_right {
  float: right;
}

/* Clear floats */
.clear,
#clear {
  height: 0.1px;
  font-size: 0.1px;
  line-height: 0.1px;
  clear: both;
}

/* Hide invisible elements */
.invisible,
.tns-visually-hidden {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.handorgel .accordion {
  display: none;
  overflow: hidden;
  height: 0;
  transition: height 0.1s ease 0.1s;
}
.handorgel .accordion.open {
  display: block;
  transition: height 0.2s ease;
}
.handorgel .accordion.active {
  overflow: visible;
}

.nav-list ul, .nav-list li,
.mod_navigation ul,
.mod_navigation li,
.mod_changelanguage ul,
.mod_changelanguage li,
.mod_breadcrumb ul,
.mod_breadcrumb li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.list-items ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.list-items li {
  display: block;
  margin: 20px 0;
  padding-left: 30px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
.row > * {
  padding-left: 15px;
  padding-right: 15px;
}
.row--justify-left {
  justify-content: flex-start;
}
.row--justify-center {
  justify-content: center;
}
.row--justify-right {
  justify-content: flex-end;
}
.row--align-start {
  align-items: flex-start;
}
.row--align-center {
  align-items: center;
}
.row--align-end {
  align-items: flex-end;
}
.row-dir--row-reverse {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
@media screen and (min-width: 768px) {
  .row-dir-md--row-reverse {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}
@media screen and (min-width: 1345px) {
  .row-dir-lg--row-reverse {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

.col {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
.col--justify-left {
  justify-self: flex-start;
}
.col--justify-center {
  justify-self: center;
}
.col--justify-right {
  justify-self: flex-end;
}
.col--align-start {
  align-self: flex-start;
}
.col--align-center {
  align-self: center;
}
.col--align-end {
  align-self: flex-end;
}
.col--align-margin {
  padding-top: 75px;
  padding-bottom: 75px;
}
@media screen and (max-width: 767px) {
  .col--align-margin .content-text.lead,
  .col--align-margin .content-hyperlink {
    margin-bottom: 0 !important;
  }
}
@media screen and (min-width: 768px) {
  .col--align-margin {
    padding-top: 150px;
    padding-bottom: 70px;
  }
}

.col-1 {
  padding-left: 15px;
  padding-right: 15px;
  width: 8.3333333333%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.col-2 {
  padding-left: 15px;
  padding-right: 15px;
  width: 16.6666666667%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.col-3 {
  padding-left: 15px;
  padding-right: 15px;
  width: 25%;
}

.offset-3 {
  margin-left: 25%;
}

.col-4 {
  padding-left: 15px;
  padding-right: 15px;
  width: 33.3333333333%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.col-5 {
  padding-left: 15px;
  padding-right: 15px;
  width: 41.6666666667%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.col-6 {
  padding-left: 15px;
  padding-right: 15px;
  width: 50%;
}

.offset-6 {
  margin-left: 50%;
}

.col-7 {
  padding-left: 15px;
  padding-right: 15px;
  width: 58.3333333333%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.col-8 {
  padding-left: 15px;
  padding-right: 15px;
  width: 66.6666666667%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.col-9 {
  padding-left: 15px;
  padding-right: 15px;
  width: 75%;
}

.offset-9 {
  margin-left: 75%;
}

.col-10 {
  padding-left: 15px;
  padding-right: 15px;
  width: 83.3333333333%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.col-11 {
  padding-left: 15px;
  padding-right: 15px;
  width: 91.6666666667%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

.col-12 {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.offset-12 {
  margin-left: 100%;
}

@media screen and (min-width: 768px) {
  .col-md-1 {
    padding-left: 15px;
    padding-right: 15px;
    width: 8.3333333333%;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .col-md-2 {
    padding-left: 15px;
    padding-right: 15px;
    width: 16.6666666667%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .col-md-3 {
    padding-left: 15px;
    padding-right: 15px;
    width: 25%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .col-md-4 {
    padding-left: 15px;
    padding-right: 15px;
    width: 33.3333333333%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .col-md-5 {
    padding-left: 15px;
    padding-right: 15px;
    width: 41.6666666667%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .col-md-6 {
    padding-left: 15px;
    padding-right: 15px;
    width: 50%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .col-md-7 {
    padding-left: 15px;
    padding-right: 15px;
    width: 58.3333333333%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .col-md-8 {
    padding-left: 15px;
    padding-right: 15px;
    width: 66.6666666667%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .col-md-9 {
    padding-left: 15px;
    padding-right: 15px;
    width: 75%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .col-md-10 {
    padding-left: 15px;
    padding-right: 15px;
    width: 83.3333333333%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .col-md-11 {
    padding-left: 15px;
    padding-right: 15px;
    width: 91.6666666667%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
  .col-md-12 {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
  .offset-md-12 {
    margin-left: 100%;
  }
}
@media screen and (min-width: 1345px) {
  .col-lg-1 {
    padding-left: 15px;
    padding-right: 15px;
    width: 8.3333333333%;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .col-lg-2 {
    padding-left: 15px;
    padding-right: 15px;
    width: 16.6666666667%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .col-lg-3 {
    padding-left: 15px;
    padding-right: 15px;
    width: 25%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .col-lg-4 {
    padding-left: 15px;
    padding-right: 15px;
    width: 33.3333333333%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .col-lg-5 {
    padding-left: 15px;
    padding-right: 15px;
    width: 41.6666666667%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .col-lg-6 {
    padding-left: 15px;
    padding-right: 15px;
    width: 50%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .col-lg-7 {
    padding-left: 15px;
    padding-right: 15px;
    width: 58.3333333333%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .col-lg-8 {
    padding-left: 15px;
    padding-right: 15px;
    width: 66.6666666667%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .col-lg-9 {
    padding-left: 15px;
    padding-right: 15px;
    width: 75%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .col-lg-10 {
    padding-left: 15px;
    padding-right: 15px;
    width: 83.3333333333%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .col-lg-11 {
    padding-left: 15px;
    padding-right: 15px;
    width: 91.6666666667%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
  .col-lg-12 {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
  .offset-lg-12 {
    margin-left: 100%;
  }
}
.section-header__inside,
.section-main__inside,
.section-footer__inside {
  padding: 0 20px;
}
@media screen and (min-width: 768px) {
  .section-header__inside,
  .section-main__inside,
  .section-footer__inside {
    max-width: 1315px;
    margin: 0 auto;
    padding: 0;
  }
}

body.has-overlay {
  overflow: hidden;
}

.section-header {
  position: relative;
  height: 80px;
}
.section-header::before {
  content: "";
  position: fixed;
  inset: 0;
  background: #7e482b;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  z-index: 800;
  pointer-events: none;
}
body.has-overlay .section-header::before {
  opacity: 1;
}
.section-header::after {
  content: "";
  position: fixed;
  inset: 0;
  background: url("../../images/navigation.svg") 0 0/cover no-repeat;
  opacity: 0;
  z-index: 810;
  pointer-events: none;
}
body.has-overlay .section-header::after {
  opacity: 0.1;
}
.section-header__inside {
  position: relative;
  padding: 20px;
}
.section-header__logo {
  position: absolute;
  display: block;
  width: 180px;
  height: 38px;
  background: url("../../images/logo--primary.svg") 0 0/contain no-repeat;
  z-index: 1000;
}
body.has-overlay .section-header__logo {
  background-image: url("../../images/logo--secondary.svg");
}
.section-header__language {
  position: absolute;
  z-index: 1000;
  top: 25px;
  right: 85px;
  transition: color 0.2s ease-in-out;
}
.section-header__language a {
  display: block;
  padding-right: 15px;
}
.section-header__language a.active::after {
  content: "";
  position: absolute;
  right: 0;
  top: 7px;
  display: block;
  margin: 0;
  padding: 0;
  width: 8px;
  height: 8px;
  border-right: 2px solid #1e1e1e;
  border-bottom: 2px solid #1e1e1e;
  transform: rotate(45deg);
  transition: border-color 0.2s ease-in-out;
}
.section-header__language ul {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.1s ease-in-out;
  z-index: 10;
}
.section-header__language:hover ul, .section-header__language--hover ul {
  opacity: 1;
  pointer-events: auto;
}
body.has-overlay .section-header__language {
  color: #d9af97;
}
body.has-overlay .section-header__language a.active::after {
  border-color: #d9af97;
}
.section-header__toggle {
  position: absolute;
  right: 20px;
  top: 30px;
  width: 32px;
  height: 18px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000;
}
.section-header__toggle span {
  display: block;
  width: 32px;
  height: 3px;
  background: #1e1e1e;
  border-radius: 10px;
  transition: all 0.1s ease-in-out;
}
.section-header__toggle span:before, .section-header__toggle span:after {
  content: "";
  position: absolute;
  left: 0;
  width: 32px;
  height: 3px;
  background: #1e1e1e;
  border-radius: 10px;
  transition: all 0.1s ease-in-out;
}
.section-header__toggle span:before {
  top: 0;
}
.section-header__toggle span:after {
  bottom: 0;
}
body.has-overlay .section-header__toggle span {
  background: transparent;
}
body.has-overlay .section-header__toggle span:before {
  top: 8px;
  background: #d9af97;
  transform: rotateZ(45deg);
}
body.has-overlay .section-header__toggle span:after {
  bottom: 8px;
  background: #d9af97;
  transform: rotateZ(-45deg);
}
.section-header__overlay {
  opacity: 0;
  pointer-events: none;
  display: block;
  position: fixed;
  overflow-y: auto;
  top: 79px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 20px;
  color: #d9af97;
  transition: opacity 0.2s ease-in-out;
  z-index: 999;
}
body.has-overlay .section-header__overlay {
  opacity: 1;
  pointer-events: auto;
}
.section-header__navigation {
  margin-top: 80px;
  font-size: 27px;
  line-height: 36px;
}
.section-header__navigation li {
  padding: 15px 0;
}
.section-header__navigation a,
.section-header__navigation strong {
  position: relative;
  display: inline-block;
  transition: color 0.2s ease-in-out;
  font-weight: 600;
}
.section-header__navigation a::before,
.section-header__navigation strong::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0;
  max-width: 100%;
  height: 2px;
  background: #fff;
  transition: width 0.2s ease-in-out;
}
.section-header__navigation a:hover,
.section-header__navigation a.trail,
.section-header__navigation strong {
  color: #fff;
}
.section-header__navigation a:hover::before,
.section-header__navigation a.trail::before,
.section-header__navigation strong::before {
  width: 140px;
}
.section-header .content-text {
  margin-top: 90px;
  font-size: 14px;
  line-height: 30px;
}
.section-header .content-text p {
  margin: 2em 0;
}
.section-header .content-text strong {
  font-size: 18px;
}
.section-header .content-text a:hover {
  color: #fff;
  text-decoration: underline;
}
.section-header__meta {
  margin: 60px 0 30px;
  line-height: 30px;
}
.section-header__meta ul {
  display: flex;
  justify-content: center;
}
.section-header__meta li::after {
  content: "|";
  margin: 0 15px;
}
.section-header__meta li:last-child::after {
  content: none;
}
.section-header__meta a:hover,
.section-header__meta a.trail,
.section-header__meta strong {
  color: #fff;
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .section-header {
    height: 120px;
  }
  .section-header__inside {
    padding-left: 0;
    padding-right: 0;
  }
  .section-header__logo {
    width: 328px;
    height: 70px;
  }
  .section-header__overlay {
    top: 120px;
    display: flex;
    align-items: center;
    padding-bottom: 120px;
  }
  .section-header__overlay-content {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 5fr 3fr 3fr;
    max-width: 1345px;
    margin: 0 auto;
  }
  .section-header__navigation {
    grid-row: 1/3;
    grid-column: 2;
    margin-top: 0;
    padding: 0 15px;
    text-align: right;
    font-size: 36px;
    line-height: 48px;
  }
  .section-header__navigation a::before,
  .section-header__navigation strong::before {
    left: auto;
    right: 0;
  }
  .section-header .content-text, .section-header__meta {
    grid-column: 4;
    margin-top: -1em;
    padding: 0 15px;
  }
  .section-header__meta {
    grid-row: 2;
    margin: 0;
  }
  .section-header__meta ul {
    flex-direction: column;
  }
  .section-header__meta li {
    padding: 5px 0;
  }
  .section-header__meta li::after {
    content: none;
  }
  .section-header .content-text {
    grid-row: 1;
  }
  .section-header .content-text p {
    margin: 2.5em 0;
  }
}
.section-footer {
  color: #fffefe;
  background: #7e482b;
  font-size: 14px;
}
.section-footer__inside {
  padding-top: 44px;
}
.section-footer__logo {
  display: block;
  width: 245px;
  height: 50px;
  background: url("../../images/logo--white.svg") 0 0/contain no-repeat;
}
.section-footer .content-text.address {
  margin-left: 65px;
}
.section-footer h1 {
  margin: 60px 0;
  font-size: 43px;
  line-height: 43px;
  font-weight: 600;
}
.section-footer h1 em {
  position: relative;
  z-index: 10;
  display: inline-block;
  font-family: "Playfair Display", serif;
}
.section-footer h1 em::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 9px;
  background: #d9af97;
  z-index: -1;
}
.section-footer .content-text h2 {
  margin: 0;
  padding-top: 50px;
  text-align: center;
  border-top: 1px dotted #fff;
  font-weight: 600;
}
.section-footer .content-text h2 + .rte {
  text-align: center;
}
.section-footer .content-hyperlink {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 50px;
}
.section-footer .content-hyperlink a {
  position: relative;
  display: inline-block;
  padding-right: 115px;
  border: none;
  background: none;
  color: #fffefe;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
}
.section-footer .content-hyperlink a::before {
  content: "";
  position: absolute;
  top: 10px;
  right: 0;
  width: 90px;
  height: 2px;
  margin-left: -1px;
  background: #fffefe;
}
.section-footer .content-hyperlink a:hover::before {
  animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
}
.section-footer .content-hyperlink a::after {
  content: "";
  position: absolute;
  right: 0;
  top: 7px;
  display: block;
  margin: 0;
  padding: 0;
  width: 8px;
  height: 8px;
  border-top: 2px solid #fffefe;
  border-right: 2px solid #fffefe;
  transform: rotate(45deg);
}
@keyframes elasticus {
  0% {
    transform-origin: 0 0;
    transform: scale(0, 1);
  }
  50% {
    transform-origin: 0 0;
    transform: scale(1, 1);
  }
  50.1% {
    transform-origin: 100% 0;
    transform: scale(1, 1);
  }
  100% {
    transform-origin: 100% 0;
    transform: scale(0, 1);
  }
}
.section-footer .row-dir-md--row-reverse .col + .col .content-text {
  opacity: 0.3;
  padding: 30px 0;
}
.section-footer .row-dir-md--row-reverse .col + .col .content-text p {
  margin: 0;
}
.section-footer .row-dir-md--row-reverse .col + .col .content-text a {
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .section-footer__inside {
    padding-bottom: 30px;
  }
  .section-footer__logo {
    width: 328px;
    height: 70px;
  }
  .section-footer .content-text.address {
    margin-left: 85px;
  }
  .section-footer h1 {
    margin: -40px 0 40px;
    font-size: 70px;
    line-height: 76px;
  }
  .section-footer .content-text h2,
  .section-footer .content-text h2 + .rte {
    text-align: left;
  }
  .section-footer .content-hyperlink {
    margin-bottom: 30px;
    justify-content: flex-start;
  }
  .section-footer .content-hyperlink .explanation {
    padding: 0;
  }
  .section-footer .content-hyperlink input.text {
    margin-bottom: 0;
  }
}
.section-main {
  padding-bottom: 125px;
}
.section-main h1, .section-main h2, .section-main h3, .section-main h4, .section-main h5, .section-main h6 {
  font-weight: 600;
}
.section-main h1 a, .section-main h2 a, .section-main h3 a, .section-main h4 a, .section-main h5 a, .section-main h6 a {
  color: inherit !important;
  text-decoration: none !important;
}
.section-main h1 a:hover, .section-main h2 a:hover, .section-main h3 a:hover, .section-main h4 a:hover, .section-main h5 a:hover, .section-main h6 a:hover {
  text-decoration: underline !important;
}
.section-main h1 em, .section-main h2 em, .section-main h3 em, .section-main h4 em, .section-main h5 em, .section-main h6 em {
  position: relative;
  z-index: 10;
  display: inline-block;
  font-family: "Playfair Display", serif;
}
.section-main h1 em::after, .section-main h2 em::after, .section-main h3 em::after, .section-main h4 em::after, .section-main h5 em::after, .section-main h6 em::after {
  content: "";
  position: absolute;
  right: 0;
  width: 90%;
  background: #d9af97;
  z-index: -1;
}
.section-main h1 {
  font-size: 60px;
  line-height: 66px;
  hyphens: auto;
}
.section-main h1 em::after {
  bottom: 0;
  height: 14px;
}
@media screen and (min-width: 768px) {
  .section-main h1 {
    font-size: 80px;
    line-height: 86px;
  }
}
.section-main h2 {
  margin-top: 2em;
  font-size: 30px;
}
.section-main h2 em::after {
  bottom: 5px;
  height: 9px;
}
.section-main h3 {
  font-size: 20px;
}
.section-main .content-text {
  font-size: 18px;
  line-height: 30px;
}
.section-main .content-text.lead {
  font-weight: 300;
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 70px;
}
.section-main .content-text.center {
  text-align: center;
}
.section-main .content-text a {
  color: #7e482b;
  text-decoration: underline;
}
.section-main .content-text ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.section-main .content-text li {
  position: relative;
  margin: 0 0 10px 10px;
  padding-left: 20px;
}
.section-main .content-text li::before {
  content: "–";
  position: absolute;
  left: 0;
  top: -1px;
  color: #7e482b;
}
@media screen and (max-width: 767px) {
  .section-main .content-image.left {
    margin-left: -20px;
  }
  .section-main .content-image.right {
    margin-right: -20px;
  }
}
.section-main .content-image.deko {
  position: relative;
}
.section-main .content-image.deko::after {
  content: "";
  position: absolute;
  inset: -15px -20px 15px 10px;
  background: #d9af97;
  z-index: -1;
}
@media screen and (min-width: 768px) {
  .section-main .content-image.deko::after {
    right: -30px;
  }
}
.section-main .content-hyperlink {
  margin: 60px 0 100px;
}
.section-main .content-hyperlink a {
  position: relative;
  display: inline-block;
  padding-right: 115px;
  border: none;
  background: none;
  color: #7e482b;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  cursor: pointer;
}
.section-main .content-hyperlink a::before {
  content: "";
  position: absolute;
  top: 10px;
  right: 0;
  width: 90px;
  height: 2px;
  margin-left: -1px;
  background: #7e482b;
}
.section-main .content-hyperlink a:hover::before {
  animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
}
.section-main .content-hyperlink a::after {
  content: "";
  position: absolute;
  right: 0;
  top: 7px;
  display: block;
  margin: 0;
  padding: 0;
  width: 8px;
  height: 8px;
  border-top: 2px solid #7e482b;
  border-right: 2px solid #7e482b;
  transform: rotate(45deg);
}
@keyframes elasticus {
  0% {
    transform-origin: 0 0;
    transform: scale(0, 1);
  }
  50% {
    transform-origin: 0 0;
    transform: scale(1, 1);
  }
  50.1% {
    transform-origin: 100% 0;
    transform: scale(1, 1);
  }
  100% {
    transform-origin: 100% 0;
    transform: scale(0, 1);
  }
}
.section-main .col.services {
  position: relative;
  margin: 0 0 100px;
  padding: 0 0 50px;
}
.section-main .col.services::before {
  content: "";
  position: absolute;
  inset: 0 -5px 0 20%;
  background: #d9af97;
  z-index: -1;
}
.section-main .col.services .content-hyperlink {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .section-main .col.services {
    padding: 100px 0;
    margin-left: -8.3333333333%;
    margin-bottom: 180px;
  }
  .section-main .col.services::before {
    left: 11.1111111111%;
  }
  .section-main .col.services .content-hyperlink {
    margin-bottom: 46px;
  }
}
.section-main .row:last-child .col.services {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .section-main h1 {
    font-size: 70px;
    line-height: 76px;
  }
  .section-main .content-text.lead {
    font-size: 28px;
    line-height: 44px;
  }
}
.content-newsletter {
  margin-bottom: 50px;
}
.content-newsletter__image img {
  width: 100%;
}
.content-newsletter__dateline {
  margin-top: -10px;
  font-size: 14px;
  line-height: 36px;
  color: #8a8e8d;
}
.content-newsletter__headline {
  margin: 0 !important;
  font: italic 30px/30px "Playfair Display", serif;
  color: #7e482b;
}
.content-newsletter__link {
  margin-top: 2em !important;
  margin-bottom: 0 !important;
}
.content-newsletter + .content-newsletter {
  margin-top: 100px;
}

.ce_rsce_grid-col--start.newsletter {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 440px;
  max-width: calc(100% + 40px);
  margin: 100px -20px;
  text-align: center;
  overflow: hidden;
}
.ce_rsce_grid-col--start.newsletter::after {
  content: "";
  position: absolute;
  inset: 0 -20px;
  width: calc(100% + 40px);
  height: 0;
  padding-bottom: calc(100% + 40px);
  background: #d9af97;
  border-radius: 50%;
  z-index: -1;
}
.ce_rsce_grid-col--start.newsletter .content-text,
.ce_rsce_grid-col--start.newsletter .content-hyperlink {
  font-size: 14px;
  line-height: 22px;
  margin: 0 0 20px;
  padding: 0 20px;
}
@media screen and (min-width: 400px) {
  .ce_rsce_grid-col--start.newsletter {
    height: 400px;
    max-width: 100%;
    overflow: visible;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 768px) {
  .ce_rsce_grid-col--start.newsletter {
    margin: 250px 0 0;
  }
}

.mouse {
  position: relative;
  padding: 90px 0;
}
.mouse--desktop {
  display: none;
}
.mouse__border {
  position: absolute;
  width: 22px;
  height: 42px;
  bottom: 90px;
  left: 50%;
  margin-left: -12px;
  border-radius: 15px;
  border: 2px solid #1e1e1e;
  -webkit-animation: intro 1s;
  animation: intro 1s;
}
.mouse__scroll {
  display: block;
  width: 3px;
  height: 3px;
  margin: 6px auto;
  border-radius: 4px;
  background: #1e1e1e;
  -webkit-animation: finger 1s infinite;
  animation: finger 1s infinite;
}
@keyframes intro {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes finger {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

@media screen and (min-width: 768px) {
  .mouse__border {
    bottom: 0;
  }
  .mouse--mobile {
    display: none;
  }
  .mouse--desktop {
    display: block;
  }
}