.content-newsletter {
    margin-bottom: 50px;

    &__image {
        img {
            width: 100%;
        }
    }

    &__dateline {
        margin-top: -10px;
        font-size: 14px;
        line-height: 36px;
        color: map-get($color, placeholder);
    }

    &__headline {
        margin: 0 !important;
        font: italic 30px/30px "Playfair Display", serif;
        color: map-get($color, primary);
    }

    &__link {
        margin-top: 2em !important;
        margin-bottom: 0 !important;
    }

    + .content-newsletter {
        margin-top: 100px;
    }
}

.ce_rsce_grid-col--start.newsletter {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 440px;
    max-width: calc(100% + 40px);
    margin: 100px -20px;
    text-align: center;
    overflow: hidden;

    &::after {
        content: "";
        position: absolute;
        inset: 0 -20px;
        width: calc(100% + 40px);
        height: 0;
        padding-bottom: calc(100% + 40px);
        background: map-get($color, secondary);
        border-radius: 50%;
        z-index: -1;
    }

    .content-text,
    .content-hyperlink {
        font-size: 14px;
        line-height: 22px;
        margin: 0 0 20px;
        padding: 0 20px;
    }

    @include screen(400) {
        height: 400px;
        max-width: 100%;
        overflow: visible;
        margin-left: auto;
        margin-right: auto;
    }

    @include screen(medium) {
        margin: 250px 0 0;
    }
}
