
.mouse {
    position: relative;
    padding: 90px 0;

    &--desktop {
        display: none;
    }

    &__border {
        position: absolute;
        width: 22px;
        height: 42px;
        bottom: 90px;
        left: 50%;
        margin-left: -12px;
        border-radius: 15px;
        border: 2px solid map-get($color, text);
        -webkit-animation: intro 1s;
        animation: intro 1s;
    }

    &__scroll {
        display: block;
        width: 3px;
        height: 3px;
        margin: 6px auto;
        border-radius: 4px;
        background: map-get($color, text);
        -webkit-animation: finger 1s infinite;
        animation: finger 1s infinite;
    }

    @keyframes intro {
        0% {
            opacity: 0;
            transform: translateY(40px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes finger {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            transform: translateY(20px);
        }
    }
}

@include screen(medium, '.mouse') {
    &__border {
        bottom: 0;
    }

    &--mobile {
        display: none;
    }

    &--desktop {
        display: block;
    }
}
