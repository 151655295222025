body.has-overlay {
    overflow: hidden;
}

.section-header {
    position: relative;
    height: 80px;

    &::before {
        content: "";
        position: fixed;
        inset: 0;
        background: map-get($color, primary);
        transition: opacity .2s ease-in-out;
        opacity: 0;
        z-index: 800;
        pointer-events: none;

        body.has-overlay & {
            opacity: 1;
        }
    }

    &::after {
        content: "";
        position: fixed;
        inset: 0;
        background: url("../../images/navigation.svg") 0 0/cover no-repeat;
        opacity: 0;
        z-index: 810;
        pointer-events: none;

        body.has-overlay & {
            opacity: .1;
        }
    }

    &__inside {
        position: relative;
        padding: 20px;
    }

    &__logo {
        position: absolute;
        display: block;
        width: 180px;
        height: 38px;
        background: url("../../images/logo--primary.svg") 0 0/contain no-repeat;
        z-index: 1000;

        body.has-overlay & {
            background-image: url("../../images/logo--secondary.svg");
        }
    }

    &__language {
        position: absolute;
        z-index: 1000;
        top: 25px;
        right: 85px;
        transition: color .2s ease-in-out;

        a {
            display: block;
            padding-right: 15px;

            &.active {
                &::after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 7px;
                    display: block;
                    margin: 0;
                    padding: 0;
                    width: 8px;
                    height: 8px;
                    border-right: 2px solid map-get($color, text);
                    border-bottom: 2px solid map-get($color, text);
                    transform: rotate(45deg);
                    transition: border-color .2s ease-in-out;
                }
            }
        }

        ul {
            position: absolute;
            opacity: 0;
            pointer-events: none;
            transition: opacity .1s ease-in-out;
            z-index: 10;
        }

        &:hover,
        &--hover {
            ul {
                opacity: 1;
                pointer-events: auto;
            }
        }

        body.has-overlay & {
            color: map-get($color, secondary);

            a.active::after {
                border-color: map-get($color, secondary);
            }
        }
    }

    &__toggle {
        position: absolute;
        right: 20px;
        top: 30px;
        width: 32px;
        height: 18px;
        background: none;
        border: none;
        cursor: pointer;
        z-index: 1000;

        span {
            display: block;
            width: 32px;
            height: 3px;
            background: map-get($color, text);
            border-radius: 10px;
            transition: all .1s ease-in-out;

            &:before,
            &:after {
                content: "";
                position: absolute;
                left: 0;
                width: 32px;
                height: 3px;
                background: map-get($color, text);
                border-radius: 10px;
                transition: all .1s ease-in-out;
            }

            &:before {
                top: 0;
            }

            &:after {
                bottom: 0;
            }
        }

        body.has-overlay & {
            span {
                background: transparent;

                &:before {
                    top: 8px;
                    background: map-get($color, secondary);
                    transform: rotateZ(45deg);
                }

                &:after {
                    bottom: 8px;
                    background: map-get($color, secondary);
                    transform: rotateZ(-45deg);
                }
            }
        }
    }

    &__overlay {
        opacity: 0;
        pointer-events: none;
        display: block;
        position: fixed;
        overflow-y: auto;
        top: 79px;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0 20px;
        color: map-get($color, secondary);
        transition: opacity .2s ease-in-out;
        z-index: 999;

        body.has-overlay & {
            opacity: 1;
            pointer-events: auto;
        }
    }

    &__navigation {
        margin-top: 80px;
        font-size: 27px;
        line-height: 36px;

        li {
            padding: 15px 0;
        }

        a,
        strong {
            position: relative;
            display: inline-block;
            transition: color .2s ease-in-out;
            font-weight: map-get($font-weight, semibold);

            &::before {
                content: "";
                position: absolute;
                left: 0;
                bottom: -5px;
                width: 0;
                max-width: 100%;
                height: 2px;
                background: #fff;
                transition: width .2s ease-in-out;
            }
        }

        a:hover,
        a.trail,
        strong {
            color: #fff;

            &::before {
                width: 140px;
            }
        }
    }

    .content-text {
        margin-top: 90px;
        font-size: 14px;
        line-height: 30px;

        p {
            margin: 2em 0;
        }

        strong {
            font-size: 18px;
        }

        a:hover {
            color: #fff;
            text-decoration: underline;
        }
    }

    &__meta {
        margin: 60px 0 30px;
        line-height: 30px;

        ul {
            display: flex;
            justify-content: center;
        }

        li {
            &::after {
                content: "|";
                margin: 0 15px;
            }

            &:last-child::after {
                content: none;
            }
        }

        a:hover,
        a.trail,
        strong {
            color: #fff;
            text-decoration: underline;
        }
    }
}

@include screen(medium, '.section-header') {
    height: 120px;

    &__inside {
        padding-left: 0;
        padding-right: 0;
    }

    &__logo {
        width: 328px;
        height: 70px;
    }

    &__overlay {
        top: 120px;
        display: flex;
        align-items: center;
        padding-bottom: 120px;

        &-content {
            display: grid;
            grid-template-rows: auto auto;
            grid-template-columns: 1fr 5fr 3fr 3fr;
            max-width: 1345px;
            margin: 0 auto;
        }
    }

    &__navigation {
        grid-row: 1/3;
        grid-column: 2;
        margin-top: 0;
        padding: 0 15px;
        text-align: right;
        font-size: 36px;
        line-height: 48px;

        a,
        strong {
            &::before {
                left: auto;
                right: 0;
            }
        }
    }

    .content-text,
    &__meta {
        grid-column: 4;
        margin-top: -1em;
        padding: 0 15px;
    }

    &__meta {
        grid-row: 2;
        margin: 0;

        ul {
            flex-direction: column;
        }

        li {
            padding: 5px 0;

            &::after {
                content: none;
            }
        }
    }

    .content-text {
        grid-row: 1;

        p {
            margin: 2.5em 0;
        }
    }
}
