$grid-gutter: 15px !default;
$max-width: 1200px !default;

@mixin wrapper() {
    padding: 0 $grid-gutter;

    @include screen(medium) {
        max-width: $max-width;
        margin: 0 auto;
        padding: 0 2*$grid-gutter;
    }
}

@mixin grid-row() {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$grid-gutter;
    margin-right: -$grid-gutter;

    & > * {
        padding-left: $grid-gutter;
        padding-right: $grid-gutter;
    }
}

@mixin grid-col($cols: 12, $of: 12) {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
    width: percentage(calc(1 / $of) * $cols)
}

@mixin grid-offset($cols, $of: 12) {
    margin-left: percentage(calc(1 / $of) * $cols);
}

.row {
    @include grid-row;

    &--justify-left {
        justify-content: flex-start;
    }

    &--justify-center {
        justify-content: center;
    }

    &--justify-right {
        justify-content: flex-end;
    }

    &--align-start {
        align-items: flex-start;
    }

    &--align-center {
        align-items: center;
    }

    &--align-end {
        align-items: flex-end;
    }

    &-dir {
        &--row-reverse {
            flex-direction: row-reverse;
            justify-content: flex-end;
        }

        @include screen(medium) {
            &-md--row-reverse {
                flex-direction: row-reverse;
                justify-content: flex-end;
            }
        }

        @include screen(large) {
            &-lg--row-reverse {
                flex-direction: row-reverse;
                justify-content: flex-end;
            }
        }
    }
}

.col {
    width: 100%;
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;

    &--justify-left {
        justify-self: flex-start;
    }

    &--justify-center {
        justify-self: center;
    }

    &--justify-right {
        justify-self: flex-end;
    }

    &--align-start {
        align-self: flex-start;
    }

    &--align-center {
        align-self: center;
    }

    &--align-end {
        align-self: flex-end;
    }

    &--align-margin {
        padding-top: 75px;
        padding-bottom: 75px;

        @include screen(mobile-only) {
            .content-text.lead,
            .content-hyperlink {
                margin-bottom: 0 !important;
            }
        }

        @include screen(medium) {
            padding-top: 150px;
            padding-bottom: 70px;
        }
    }
}

@for $col from 1 to 13 {
    .col-#{$col} {
        @include grid-col($col);
    }

    .offset-#{$col} {
        @include grid-offset($col);
    }
}

@include screen(medium) {
    @for $col from 1 to 13 {
        .col-md-#{$col} {
            @include grid-col($col);
        }

        .offset-md-#{$col} {
            @include grid-offset($col);
        }
    }
}

@include screen(large) {
    @for $col from 1 to 13 {
        .col-lg-#{$col} {
            @include grid-col($col);
        }

        .offset-lg-#{$col} {
            @include grid-offset($col);
        }
    }
}
