.section-main {
    padding-bottom: 125px;

    h1, h2, h3, h4, h5, h6 {
        font-weight: map-get($font-weight, semibold);

        a {
            color: inherit !important;
            text-decoration: none !important;

            &:hover {
                text-decoration: underline !important;
            }
        }

        em {
            position: relative;
            z-index: 10;
            display: inline-block;
            font-family: 'Playfair Display', serif;

            &::after {
                content: "";
                position: absolute;
                right: 0;
                width: 90%;
                background: map-get($color, secondary);
                z-index: -1;
            }
        }
    }

    h1 {
        font-size: 60px;
        line-height: 66px;
        hyphens: auto;

        em::after {
            bottom: 0;
            height: 14px;
        }

        @include screen(medium) {
            font-size: 80px;
            line-height: 86px;
        }
    }

    h2 {
        margin-top: 2em;
        font-size: 30px;

        em::after {
            bottom: 5px;
            height: 9px;
        }
    }

    h3 {
        font-size: 20px;
    }

    .content-text {
        font-size: 18px;
        line-height: 30px;

        &.lead {
            font-weight: map-get($font-weight, light);
            font-size: 24px;
            line-height: 38px;
            margin-bottom: 70px;
        }

        &.center {
            text-align: center;
        }

        a {
            color: map-get($color, primary);
            text-decoration: underline;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        li {
            position: relative;
            margin: 0 0 10px 10px;
            padding-left: 20px;

            &::before {
                content: "–";
                position: absolute;
                left: 0;
                top: -1px;
                color: map-get($color, primary);
            }
        }
    }

    .content-image {
        @include screen(mobile-only) {
            &.left {
                margin-left: -20px;
            }

            &.right {
                margin-right: -20px;
            }
        }

        &.deko {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                inset: -15px -20px 15px 10px;
                background: map-get($color, secondary);
                z-index: -1;

                @include screen(medium) {
                    right: -30px;
                }
            }
        }
    }

    .content-hyperlink {
        margin: 60px 0 100px;

        a {
            @include link();
        }
    }

    .col.services {
        position: relative;
        margin: 0 0 100px;
        padding: 0 0 50px;

        &:last-child {
            //margin-bottom: 0;
        }

        &::before {
            content: "";
            position: absolute;
            inset: 0 -5px 0 20%;
            background: map-get($color, secondary);
            z-index: -1;
        }

        .content-hyperlink {
            margin-bottom: 0;
        }

        @include screen(medium) {
            padding: 100px 0;
            margin-left: percentage(calc(-1 / 12));
            margin-bottom: 180px;

            &::before {
                left: percentage(calc(1 / 9));
            }

            .content-hyperlink {
                margin-bottom: 46px;
            }
        }
    }

    .row:last-child .col.services {
        margin-bottom: 0;
    }
}

@include screen(medium, '.section-main') {
    h1 {
        font-size: 70px;
        line-height: 76px;
    }

    .content-text {
        &.lead {
            font-size: 28px;
            line-height: 44px;
        }
    }
}
